@import 'dashboard';
@import 'dropdown';
@import 'chartjs-extensions';
@import 'transactions';
@import 'component/_all';
@import 'global-modifiers';


@media screen and (max-width: 768px) {
    body.mini-navbar #page-wrapper {
        margin-left: 0px;
        transform: translateX(70px);
        -webkit-transform: translateX(70px);
    }

    .tc-filter-wrap  {
        display: block!important;
        text-align: left;

        .checkbox-inline {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    .tc-filter-wrap > p > strong {
        display: block;
    }

    /* At this point all the filters are left-aligned. So should the dropdown list be as well. */
    .general-dropdown {
        direction: ltr;
    }
}

body.tc-env-notif {
    margin-top: 28px;
}

.tc-env-notification {
    position: absolute;
    top: 0;
    width: 100%;
    background: #c76c6c;
    z-index: 10000;
    text-align: center;
    color: #fff;
    padding: 5px 0;
}

#page-wrapper {
    padding-bottom: 60px;
}

.white-bg {
    background-color: #fff!important;
}

.gray-bg {
    background-color: #e5e9ec
}

.ibox {
    box-shadow: 0 0 7px #c7c7c7;
}

.form-control {
    border-color: #c4cfda;
}

.tc-transactions .feed-element {
    margin-top: 0;
    padding: 20px;
}

.tc-transaction-icon {
    font-size: 1.3em;
    background: #0c9fd7;
    color: #fff;
    width: 34px;
    height: 34px;
    text-align: center;
    border-radius: 50%;
    line-height: 34px;
}

.feed-element .media-body {
    width: auto;
}

.panel-body .alert {
    margin-bottom: 0;
}

.no-padding .alert {
    border-radius: 0;
    border: none;
    text-align: center;
    margin-bottom: 0;
}

.no-margin {
    margin: 0!important;
}

.wrap-word {
    white-space: normal;
    word-wrap: break-word;
}

body.tc-login {
    height: auto;
    background: url(../images/tc-background.jpg) no-repeat 50% 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &.is-customer-branding-disabled {
        background: url(../images/tc-background-bluugo.jpg) no-repeat 50% 50%;
    }
}

.tc-login-logo {
    margin: 0 auto;
    width: 200px;
    height: 140px;
    background-color: transparent;
    background-image: url(../images/logo-dark.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    &.is-customer-branding-disabled {
        background-image: url(../images/logo-dark-bluugo.png);
    }
}

.tc-client-logo {
    margin: 20px auto 0;
    display: block;
    width: 120px;
    max-width: 80%;
}

.tc-table {
    margin-bottom: 0;

    &.inplace-search {
        & > thead th {
            overflow: visible;
            position: relative;
            border-bottom: 3px solid #e2e2e2 !important;
            padding-bottom: 16px!important;

            input {
                border: none;
                border-bottom: 1px solid #ddd;
                font-weight: 400;
                padding: 0;
            }

            &.sort-active {
                input {
                    background-color: #edf3f9;
                    border-color: #cbd7e2;
                }
            }
        }

        .inplace-search-spinner {
            display: none;
            position: absolute;
            color: #13769c;
            right: 4px;
            bottom: 17px;
            padding: 4px;
            background: rgba(255, 255, 255, 0.7);
        }

        .loading ~ .inplace-search-spinner {
            display: block;
        }
    }

   & > thead th {
        white-space: nowrap;
    }

    .pagination {
        margin-bottom: 0;
    }

    td > a {
        display: block;
    }

    td {
        label {
            font-weight: normal;
        }
    }

    tfoot > tr > td {
        padding-bottom: 0;
    }
}

.tc-table-scroll-container {
    position: relative;
    max-width: 100%;
    overflow: auto;
}

.tc-table th.sort-active,
.tc-table td.sort-active {
    background: #edf3f9;
    border-color: #cad8e6!important;
}

.tc-table .sort-field,
.tc-table .no-sort-field {
    /* text-transform: capitalize; */

    &.inline-block {
        display: inline-block;
    }

    &.block {
        display: block;
    }
}

.tc-table .no-sort-field {
    font-weight: 600;
    color: #757575;
}

.tc-table .sort-field {
    color: #666;
    font-weight: 700;

    &.sort-asc:after {
        display: inline-block;
        margin-top: 4px;
        font: normal normal normal 12px/1 FontAwesome;
        width: 14px;
        height: 14px;
        content: "\f078";
    }

    &.sort-desc:after {
        display: inline-block;
        margin-top: 4px;
        font: normal normal normal 12px/1 FontAwesome;
        width: 14px;
        height: 14px;
        content: "\f077";
    }

    &:after {
        float: right;
    }

}

.input-group-btn .btn {
    min-height: 34px;
}

strong, b {
    font-weight: 600;
}

.font-thin {
    font-weight: 100;
}

.pagination {
    display: block;
}

.text-primary {
    color: #0c9fd7;
}

.bg-primary-full {
    background-color: #0c9fd7!important;
}

.border-primary {
    border-color: #0c9fd7!important;
}

.tc-filter-form {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 14px;
}

.tc-filter-clear {
    display: inline;
    overflow: hidden;
    text-align: right;
}

.tc-filter-clear::after {
    content: '|';
    color: #c7c7c7;
    padding: 0 5px;
}
.tc-filter-clear:last-child::after {
    content: none;
}

.tc-filter-wrap {
    display: inline-block;

    strong {
        margin-right: 8px;
        position: relative;
        top: 2px;
    }
}

.loginscreen {
    padding: 0;
    position: relative;
    background: #fff;
    background: rgba(255, 255, 255, .9);
    box-shadow: 0 0 7px #cbdfea;

    &.middle-box {
        width: 90%;
        max-width: 400px;
    }

    &.tc-login-form {
        max-width: 330px;
    }

    form {
        text-align: left;
    }

    hr {
        border-color: #d3e3ec;
    }

    &>div {
        padding: 20px;
        margin: 50px 0;
    }
    ul {
        list-style: none;
        list-style: none;
        border-left: 5px solid #4CAF50;
        padding-left: 15px;
        margin-bottom: 30px;

        li {
            margin-bottom: 10px;
        }
    }
}

.green-bg {
    background-color: #4CAF50;
}

.widget.green-bg {
    color: #ffffff;
}

.tc-copyright {
    text-align: right;
    font-size: .8em;

    a {
        color: inherit;
    }

    .text-muted {
        color: #d3d4d4;
    }
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}


.image-wrapper {
    background-color: #FAFAFA;
    box-shadow: 0 0 7px #c7c7c7;
    padding: 16px;
    margin-bottom: 16px;

    .image {
        position: relative;

        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 328px;

        .timestamp {
            position: absolute;
            bottom: -24px;
            right: 0;
            background-color: #fbfafa;
            color: grey;
            padding: 0 8px;
        }
    }

    header {
        margin: -16px -16px 16px -16px;
        padding: 8px;
        border-bottom: 1px solid #e7eaec;

        .icon {
            color: #BDBDBD;
        }
    }

    footer {
        margin: 16px -16px -16px -16px;
        padding: 8px;
        border-top: 1px solid #e7eaec;

        form {
            display: inline-block;
        }

        .delete-button:hover {
            color: #fff;
            background-color: #d9534f;
            border-color: #d43f3a;
        }
    }
}

.tc-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
        flex: 1 1 auto;
    }

    & > .tc-flex-shrink {
        flex: 0 1 auto;
    }
}

.model-change-log-item {
    display: inline-block;
    margin: 2px;
    border-radius: 2px;
    background-color: #5bc0de;
    color: white;
    padding: 0 2px;
}

.bootstrap-file-input {
    max-width: 150px;

    input[type="file"] {
        width: 0 !important;
        height: 0 !important;
        outline: none !important;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        /* Just to be safe, amirite guys? */
        visibility: hidden !important;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }
}

.lazy-model-choice-field {
    .input-group {
        position: relative;
        width: 100%;
    }
    
    .search-field {
        @mixin readonlyStyles {
            background-color: #ffffff;
        }
        &[readonly] {
            @include readonlyStyles;   
        }
        
        &:-moz-read-only {
            @include readonlyStyles;
        }

        &[data-is-readonly="1"] {
            background-color: #eee;
            pointer-events: none;
        }

        &[data-is-disabled="1"] {
            background-color: #eee;
            pointer-events: none;
        }

        padding-right: 24px;
    }

    .change-button {
        width: 40px;
        border-radius: 0;
    }

    .clear-button {
        position: absolute;
        right: 2px;
        top: 0;
        z-index: 2;
        background-color: transparent;
        color: #BDBDBD;
        outline: none !important;

        &:hover {
            color: #757575;
        }
    }

    .option-list {
        position: absolute;
        top: 33px;
        left: 0;
        width: calc(100% - 8px);
        z-index: 3;
        max-height: 300%;
        overflow-y: auto;
        
        background-color: #ffffff;
        border: 1px solid #0c9fd7;
        padding-top: 4px;

        .option, .option-empty {
            padding: 0.25em 0.5em;
        }

        .option {
            &:hover {
                cursor: pointer;
                background-color: lighten(#1e90ff, 10%);
                color: #ffffff;
            }

            &.is-selected {
                background-color: #1e90ff;
                color: #ffffff;
            }
        }

        .option-empty {
            color: grey;
            font-style: italic;
        }
    }

    // State overrides
    &.has-value-selected {

    }
    &:not(.has-options-visible) {
        .option-list {
            display: none;
        }
    }
    &:not(.has-value-selected) {
    }

    &.is-loading {
        .search-icon {
            display: none;
        }
    }
    &:not(.is-loading) {
        .loading-icon {
            display: none;
        }
    }
    &:not(.is-search-active) {
        .change-button {
            border: 1px solid #c4cfda;
            background-color: #ffffff;
            color: #676a6c;
        }
    }
    &.has-options-visible {
        .change-button {
            border-bottom-left-radius: 0;
        }
    }
}

.navbar-static-side {
    .sidebar-collapse > .nav > li.is-soft-expanded:not(.is-current) {
        &, a:hover, a:focus {
            background-color: rgba(255, 255, 255, 0.25) !important;
        }
    }
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    .controls {
        margin-right: -2px;
    }
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

// Helper for setting crispy forms Fieldset legend font size
.legend-font-md {
    legend {
        font-size: 16px !important;
    }
}

.legend-font-sm {
    legend {
        font-size: 12px !important;
    }
}

// Formset rows / containers as children of various containers
.panel-body, .ibox-content {
    [data-is-formset-row], [data-is-formset-insert-row-control] {
        margin-left: -20px;
        margin-right: -20px;

        &.formset-no-negative-margin {
            margin-left: initial;
            margin-right: initial;
        }
    }
}

[data-is-formset-row] {
    padding: 16px 0;
    border-top: 1px solid #e5e5e5;

    &:first-child {
    }

    &:nth-child(odd) {
        background-color: #f9f9f9;

        &.formset-no-altering-colors {
            background-color: initial;
        }
    }

    // Fix transparent button
    .formsetItemDelete {
        background-color: white;

        &:hover {
            background-color: #ec4758;
        }
    }
    
    &.formset-no-borders {
        border: none;
    }
}

[data-is-formset-insert-row-control] {
    padding-top: 32px;
    border-top: 1px solid #e5e5e5;
}

.input-group-addon.is-disabled {
    // Copied from disabled .form-control
    background-color: #eee;
    border-color: #c4cfda;
}
