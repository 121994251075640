.general-dropdown {
    display: inline-block;
    direction: rtl;
    
    button.dropdown-toggle {
        width: 100%;
        direction: ltr;
        .pull-left {
            margin-right: 16px;
        }
    }

    .manual-submit-wrapper {
        padding: 0px 4px 4px 4px;
        display: inline-block;
        width: 100%;
        button.dropdown-manualsubmit {
            width: 100%;
        }
    }

    .dropdown-menu {
        direction: ltr;
        top: inherit;
        left: inherit;
        width: inherit;
        right: inherit;
        padding: 8px;
        .item {
            display: block;
            padding: 2px 4px;
            text-align: left;
            
            label {
                font-weight: normal;
                white-space: nowrap;
            }

            input {
                position: relative;
                top: 3px;
                margin-right: 6px;
            }
        }
    }


}


.general-dropdown.reversed {
    direction: ltr;
}