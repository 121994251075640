$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

.tc-transaction.primary .tc-transaction-icon {
    background-color: $brand-primary;
}

.tc-transaction.success .tc-transaction-icon {
    background-color: $brand-success;
}

.tc-transaction.info .tc-transaction-icon {
    background-color: $brand-info;
}

.tc-transaction.warning .tc-transaction-icon {
    background-color: $brand-warning;
}

.tc-transaction.danger .tc-transaction-icon {
    background-color: $brand-danger;
}