.chart-dataset-toggles {
    text-align: center;

    .dataset-toggle {
        display: inline-block;
        margin: 0px 4px;

        label {
            font-weight: normal;

            .text {
                border-bottom: 2px solid black;
            }
        }

        input {
            margin-right: 4px;
            vertical-align: bottom;
            position: relative;
            top: -1px;
        }
    }
}