$badge-color-variants: (
    orange: (#ff8f00, #ffffff)
);

[data-badge] {
    position: relative;

    &::after {
        content: attr(data-badge);   
        display: flex;
        align-items: center;
        justify-content: center; 
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        transform: translateX(25%) translateY(-25%);
        line-height: 1;
        min-width: 1.25em;
        padding: 0.15em;
        border-radius: 50%;
        background-color: #2f2f2f;
        color: #ffffff;
    }

    @each $name, $pair in $badge-color-variants {
        $background-color: nth($pair, 1);
        $color: nth($pair, 2);

        &[is-#{$name}]::after {
            background-color: $background-color;
            color: $color;
        }
    }
}
