.min-max-wdg .db-widget-value {
    font-size: 1.1em;
    font-weight: 600;
}

.db-alert-widget {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: 32px 30px;

    .db-alert-icon-holder {
        z-index: 0;
        position: absolute;
        width: 100%;
        left: -35%;
        text-align: center;
        opacity: .3;
        line-height: 1;
        font-size: 9em;
        top: 16px;
    }

    .db-alert-content {
        z-index: 1;
        position: relative;
    }
}

.db-info-icon {
    font-size: 22px;
    position: relative;
    left: 8px;
    bottom: 2px;
    color: #8e9eab;
}

.db-info-icon-widget {
    font-size: 22px;
    position: relative;
    right: 8px;
    top: 8px;
    color: white;
    z-index: 1;
}

.db-dn-heading a {
    color: black;
}

.db-hdr-label {
    white-space: nowrap;
    width: auto;
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.db-hdr-title {
    display: block;
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.db-sm-text {
    color: white;
}

.db-tooltip {
    opacity: 1;
    z-index: 99;

    ul, ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        color: inherit;
        font-weight: 600;
    }
}

.db-tooltip .db-tooltip-text {
    visibility: hidden;
    max-width: 100%;
    top: -10px;
    left: 20%;
    background-color: #90afc5;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    color: #323536;
}

.db-tooltip .db-tooltip-text-extra {
    bottom: 98%;
}

.db-tooltip:hover .db-tooltip-text {
    visibility: visible;
    opacity: 1;
}

.db-tooltip .db-tooltip-text::after {
    content: " ";
    position: absolute;
    top: 8px;
    left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #90afc5;
}