// Border modifiers
$border-directions: top, bottom, left, right;
@each $border-direction in $border-directions {
    .has-no-border-#{$border-direction} {
        border-#{$border-direction}: none !important;
    }    
    
    .has-transparent-border-#{$border-direction} {
        border-#{$border-direction}-color: transparent !important;
    }

    .has-border-#{$border-direction} {
        border-#{$border-direction}: 1px solid #e7eaec !important;
    }
}

// Display modifiers
$display-modes: none, inline, inline-block, block, flex, inline-flex;
@each $display-mode in $display-modes {
    .is-display-#{$display-mode} {
        display: $display-mode !important;
    }
}

$padding-directions: (
    (t, top),
    (r, right),
    (b, bottom),
    (l, left),
);

$padding-steps: (
    (xs, 5px),
    (sm, 10px),
    (md, 20px),
    (lg, 30px),
    (xl, 40px),
);


@each $step in $padding-steps {
    $step-prefix: nth($step, 1);
    $step-size: nth($step, 2);

    @each $direction in $padding-directions {
        $direction-prefix: nth($direction, 1);
        $direction-property: nth($direction, 2);
        
        .p-#{$direction-prefix}-#{$step-prefix} {
            padding-#{$direction-property}: $step-size !important;
        }
    }
}

$opacity-step: 0;

@while $opacity-step < 1 {
    $opacity-step: $opacity-step + 0.05;
    $prefix: '';
    @if $opacity-step < 0.1 {
        $prefix: '0';
    }

    .opacity-#{$prefix}#{$opacity-step * 100} {
        opacity: $opacity-step;
    }
}

.hover-full-opacity:hover {
    opacity: 1;
}   

/* Used for buttons in crispy forms to add offset (label) */
.m-t-23px {
    margin-top: 23px;
}

.nav-tabs > li.is-disabled > a {
    opacity: 0.5;

    &:hover {
        cursor: not-allowed;
    }
}

.font-xs { font-size: 10px; }
.font-sm { font-size: 12px; }
.font-md { font-size: 16px; }
.font-lg { font-size: 18px; }
.font-xl { font-size: 22px; }
